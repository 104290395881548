import React, { FC } from 'react';
import {
  AboutNominationsContainer,
  NominationsItem,
} from '../AboutNominations';
import useScreen from '../../../utils/hooks/useScreen';
import { Slider } from '../../common/Slider';
import styles from './styles.module.scss';
import { IRatingsQueryEdge } from '../../../queries/ratings/types';

type IProps = {
  data: IRatingsQueryEdge['node']['frontmatter'][];
};

const AboutRatings: FC<IProps> = ({ data }) => {
  const { isMobile } = useScreen();

  return isMobile ? (
    <div className={styles.wrap}>
      <Slider
        sliderProps={{
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 20,
          controls: true,
          nav: false,
          center: false,
          lazyload: false,
          swipeAngle: 45,
          loop: false,
        }}
        className={styles.slider}
        color="#0062FF"
      >
        {data.map(({ year, title, description, link }, i) => (
          <NominationsItem key={link}>
            <span>{year}</span>
            <h4>{title}</h4>
            <span>{description}</span>
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          </NominationsItem>
        ))}
      </Slider>
    </div>
  ) : (
    <AboutNominationsContainer>
      {data.map(({ year, title, description, link }, i) => (
        <NominationsItem key={link}>
          <span>{year}</span>
          <h4>{title}</h4>
          <span>{description}</span>
          <a href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        </NominationsItem>
      ))}
    </AboutNominationsContainer>
  );
};

export { AboutRatings };
