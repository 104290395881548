/* eslint-disable react/no-array-index-key */
import React, { FC, SyntheticEvent } from 'react';
import { IDirectionTagsQueryNode } from '../../../queries/directionTags/types';
import { Card } from '../../common/Card';
import { GridWithSlider } from '../../common/GridWithSlider';
import { ICONS } from '../../../constants/icons';
import styles from './styles.module.scss';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  nodes: IDirectionTagsQueryNode[];
}

type urlStrings = string[];
type textButtons =
  | {
      text: string;
      url: string;
      key: string;
    }[]
  | undefined;

const icons = ['twitter', 'telegram'];

const SocialLinks = ({ urls }: { urls: urlStrings }) => {
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {urls.map((url, i) => (
        <a
          href={url}
          key={url}
          target="_blank"
          className={styles.socialLink}
          onClick={handleClick}
          rel="noreferrer"
        >
          <svg
            viewBox="0 0 24 24"
            fill="#684CDC"
            xmlns="http://www.w3.org/2000/svg"
          >
            {ICONS[icons[i]]}
          </svg>
        </a>
      ))}
    </>
  );
};

const TextButtons = ({ textButtonsArr }: { textButtonsArr: textButtons }) => (
  <>
    {textButtonsArr &&
      textButtonsArr.map((textButton, index) => (
        <div className={styles.textButtonWrapper} key={`textButton${index}`}>
          <TLink to={textButton.url}>
            <span className={styles.textButtonText}>{textButton.text}</span>
            <span className={styles.textButtonArrow}>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z"
                  fill="#684CDC"
                />
              </svg>
            </span>
          </TLink>
        </div>
      ))}
  </>
);

const getButtons = (urls: urlStrings, textButtonsArr: textButtons) => {
  if (textButtonsArr && textButtonsArr.length > 0) {
    return <TextButtons textButtonsArr={textButtonsArr} />;
  }
  if (urls && urls.length > 1) {
    return <SocialLinks urls={urls} />;
  }
  return undefined;
};

const AboutExpertise: FC<IProps> = ({ nodes }) => (
  <GridWithSlider>
    {nodes.map(({ node }: IDirectionTagsQueryNode) => {
      const urls = node.frontmatter.url?.split(' ') || '';
      const textButtonsArr = node.frontmatter.textButtons;
      return (
        <div key={node.fileAbsolutePath}>
          <Card
            node={node}
            from="/"
            headDirection="column"
            buttons={getButtons(urls, textButtonsArr)}
          />
        </div>
      );
    })}
  </GridWithSlider>
);

export { AboutExpertise };
