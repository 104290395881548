import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './styles.module.scss';

const NominationsItem: FC = ({ children }) => (
  <div className={styles.nominations__item}>{children}</div>
);
type IProps = {
  containerClassName?: string;
  className?: string;
};

const AboutNominationsContainer: FC<IProps> = ({
  children,
  containerClassName,
  className,
}) => (
  <div className={classNames('content', containerClassName)}>
    <div className={classNames(styles.nominations, className)}>{children}</div>
  </div>
);

export { AboutNominationsContainer, NominationsItem };
