import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Slider } from '../../common/Slider';
import useScreen from '../../../utils/hooks/useScreen';
import { usePathPrefix } from '../../../constants/hooks';
import {
  AboutNominationsContainer,
  NominationsItem,
} from '../AboutNominations';

type IProps = {
  data: {
    img: string;
    title: string;
    description: string;
    href: string;
  }[];
};

const AboutAwards: FC<IProps> = ({ data }) => {
  const prefix = usePathPrefix();
  const { isMobile } = useScreen();

  return isMobile ? (
    <div className={styles.wrap}>
      <Slider
        sliderProps={{
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 20,
          controls: true,
          nav: false,
          center: false,
          lazyload: false,
          swipeAngle: 45,
          loop: false,
        }}
        className={styles.slider}
        color="#0062FF"
      >
        {data.map(({ img, title, description, href }, i) => (
          <div key={href}>
            <NominationsItem>
              <div className={styles.slideHeader}>
                {img && <img src={img} alt={title} />}
                <h4>{title}</h4>
              </div>
              <span>{description}</span>
              <a href={href} target="_blank" rel="noreferrer">
                {href}
              </a>
            </NominationsItem>
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    <AboutNominationsContainer
      containerClassName={styles.awardsContainer}
      className={styles.awards}
    >
      <div className={styles.trophy}>
        <img src={`${prefix}/trophy.svg`} alt="Awards" />
      </div>
      {data.map(({ img, title, description, href }, i) => (
        <NominationsItem key={href}>
          {img && <img src={img} alt={title} />}
          <h4>{title}</h4>
          <span>{description}</span>
          <a href={href} target="_blank" rel="noreferrer">
            {href}
          </a>
        </NominationsItem>
      ))}
    </AboutNominationsContainer>
  );
};

export { AboutAwards };
